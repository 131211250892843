// import Splide from '@splidejs/splide';
import ('../../../../node_modules/@splidejs/splide').then((module) => {
    const Splide = module.default;
    const sliderElements = document.querySelectorAll('[data-module="slider"]');
    
    if (sliderElements.length) {
        sliderElements.forEach(e => {
            new Splide(e).mount();
        });
    }
});
