const navigation = document.querySelector('[data-module="navigation"]');
const items = navigation.querySelectorAll('a');

items.forEach(i => {
    if (!i.hash) return;
    const target = document.querySelector(i.hash);
    if (!target) return;

    i.addEventListener('click', (e) => {
        e.preventDefault();
        
        const headerOffset = window.gpUtils?.getBreakpoint() === 'sm' ? 0 : 100;
        const elementPosition = target.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
        });

        target.setAttribute('id', i.hash);
        location.hash = i.hash;
        target.setAttribute('id', i.hash.substr(1));
    });
});
